<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <b-img
          :src="require('@/assets/images/logo/logo.svg')"
          alt="Surfe logo"
          style="max-width:150px"
        />
      </b-link>

      <!-- Feedback-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          v-if="!sent"
          sm="8"
          md="6"
          lg="8"
          xl="6"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="mb-2 font-weight-bolder font-mono"
          >
            We regret to see you go!
          </b-card-title>

          <b-card-text class="mb-7 text-muted">
            Would you mind briefly telling us about why you're canceling your account?
          </b-card-text>

          <b-form-group class="mb-7">
            <b-form-checkbox-group
              v-model="reasons"
              :options="options"
              stacked
            />
          </b-form-group>

          <b-form-textarea
            v-model="comment"
            placeholder="Want to say something else?"
            rows="2"
          />

          <div class="row mt-3">
            <div class="col-xs-6 col-xl-6 item">
              <b-button
                class="w-100"
                variant="primary"
                :disabled="sendDisabled"
                @click="send"
              >
                Send
              </b-button>
            </div>
            <div class="col-xs-6 col-xl-6 item">
              <b-button
                class="w-100"
                variant="outline-primary"
                to="/login"
              >
                Skip
              </b-button>
            </div>
          </div>
        </b-col>

        <b-col
          v-else
          sm="8"
          md="6"
          lg="8"
          xl="6"
          class="px-xl-2 mx-auto"
        >
          Thank you for your feedback!
        </b-col>
      </b-col>

      <!-- Cover -->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center justify-content-center p-5 bg-primary bg-lighten-5"
      >
        <div
          class="d-flex position-relative"
          style="width: 433px; height: 340px;"
        >
          <b-img
            fluid
            :src="require('@/assets/images/wave-blue.svg')"
            alt="Cancel illustration"
            class="position-absolute position-left-0 position-right-0"
            style="top: 67.15%; bottom: 3.41%;"
          />
          <b-img
            fluid
            :src="require('@/assets/images/uninstall-illustration.png')"
            :srcset="require('@/assets/images/uninstall-illustration.png') + ' 1x, ' + require('@/assets/images/uninstall-illustration@2x.png') + ' 2x'"
            alt="Cancel illustration"
            class="position-absolute"
            style="left: 3.23%; right: 3.23%; top: 14.71%; bottom: 11.76%;"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BFormTextarea, BCardText, BCardTitle, BImg, BButton, BFormGroup, BFormCheckboxGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormTextarea,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    BFormGroup,
    BFormCheckboxGroup,
  },
  data() {
    return {
      options: [
        { text: 'Too expensive for my usage', value: 'Too expensive' },
        { text: 'Does not answer my needs', value: 'Not answer my needs' },
        { text: 'Not enough features', value: '"Not enough features' },
        { text: 'Too many bugs/issues', value: 'Too many bugs/issues' },
        { text: 'Switching to another product', value: 'Switching to another product' },
        { text: 'I will come back!', value: 'I will come back' },
      ],
      reasons: [],
      comment: '',
      sent: false,
    }
  },
  computed: {
    sendDisabled() {
      return this.reasons.length === 0 && this.comment.length === 0
    },
  },
  methods: {
    async send() {
      try {
        await this.$store.dispatch('api/deleteAccountEvent', {
          reasons: this.reasons.join(', '),
          comment: this.comment,
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Feedback sent successfully',
            icon: 'SendIcon',
            variant: 'success',
          },
        })
        this.sent = true
        setTimeout(() => {
          this.$router.push('/login')
        }, 2000)
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        throw error
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.custom-checkbox {
  margin: .8em 0;
}
</style>
